import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from './assets/privilege_logo_high_res.png';

function Navbar() {
  const [isMobile, setIsMobile] = useState(false);

  return (
    <div className="navbar-wrapper">
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo">
            <img src={logo} alt="Logo" />
          </Link>
          <ul className={isMobile ? "navbar-menu active" : "navbar-menu"}>
            {/* <li><Link to="/about" className="navbar-link">About</Link></li> */}
            <li><Link to="/support" className="navbar-link">Support</Link></li>
            {/* Add more nav items if needed */}
          </ul>
          <button className="mobile-menu-icon" onClick={() => setIsMobile(!isMobile)}>
            {isMobile ? <span>&#10005;</span> : <span>&#9776;</span>}
          </button>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;