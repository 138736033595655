import React, { useRef, useState, useEffect } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { FaAppStore, FaGooglePlay } from 'react-icons/fa';  // Add this import
import './Home.css';
import Image from './components/Image';
import img1 from './assets/PrivImg1.png';
import img2 from './assets/PrivImg2.png';

function Home() {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"]
  });

  const scale = useTransform(scrollYProgress, [0, 0.35], [1, 0.8]);
  // Adjust opacity to fade out completely by 25% scroll
  const opacity = useTransform(scrollYProgress, [0, 0.35], [1, 0]);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [showModal]);

  const handleDownloadClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <div className="modern-home" ref={containerRef}>
      <motion.section 
        className="hero-section"
        style={{ scale, opacity }}
      >
        <h1 className="gradient-text">PRIVILEGE</h1>
        <p className="hero-subtitle">Your Night, Elevated</p>
        <motion.div 
          className="scroll-indicator"
          animate={{ y: [0, 10, 0] }}
          transition={{ repeat: Infinity, duration: 2 }}
        >
          Scroll to Explore
        </motion.div>
      </motion.section>

      {showModal && (
        <div className="modal-overlay">
          <motion.div 
            className="modal-content"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
          >
            <h3 className="gradient-text secondary" style={{ fontSize: '2.5rem' }}>
              Coming Soon!
            </h3>
            <p>We know you are as excited as us. We will be live soon!</p>
            <p>Check back later gator? 🐊</p>
            <motion.button 
              className="btn-download"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setShowModal(false)}
            >
              Got it!
            </motion.button>
          </motion.div>
        </div>
      )}
      <section className="cta-section">
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ 
            duration: 0.8,
            type: "spring",
            stiffness: 400,
            damping: 30
          }}
          viewport={{ once: true }}
          className="cta-content"
        >
          <div className="cta-left">
            <h2 className="gradient-text secondary">Join The Experience</h2>
            <div className="download-buttons">
              <motion.a 
                href="#"
                className="btn-download ios"
                whileHover={{ scale: 1.05, x: 10 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleDownloadClick}
              >
                <FaAppStore className="store-icon" />
                App Store
              </motion.a>
              <motion.a 
                href="#"
                className="btn-download android"
                whileHover={{ scale: 1.05, x: 10 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleDownloadClick}
              >
                <FaGooglePlay className="store-icon" />
                Google Play
              </motion.a>
            </div>
          </div>
          <div className="cta-right">
            <div className="app-previews">
              <motion.div 
                className="preview-container"
                whileHover={{ scale: 1.1, rotate: -5 }}
                transition={{ 
                  type: "spring", 
                  stiffness: 300,
                  damping: 20
                }}
              >
                <img 
                  src={img1} 
                  alt="App Preview 1"
                  className="preview-image"
                />
              </motion.div>
              <motion.div 
                className="preview-container"
                whileHover={{ scale: 1.1, rotate: 5 }}
                transition={{ 
                  type: "spring", 
                  stiffness: 300,
                  damping: 20
                }}
              >
                <img 
                  src={img2} 
                  alt="App Preview 2"
                  className="preview-image"
                />
              </motion.div>
            </div>
          </div>
        </motion.div>
      </section>
    </div>
  );
}

export default Home;