import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Support.css';
import Card from './components/Card';
import { FaInstagram, FaEnvelope, FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa';

function Support() {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    issue_type: '',
    issue_description: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    const toastId = toast.loading("Sending your message...", {
      position: "top-center",
    });

    try {
      const response = await fetch('https://privilege-qa.el.r.appspot.com/report_issue', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'landing-guest'
        },
        body: JSON.stringify({
          name: formData.name,
          phone: formData.phone,
          issue_type: formData.issue_type,
          issue_description: formData.issue_description,
        })
      });

      if (!response.ok) throw new Error('Submission failed');
      
      setFormData({
        name: '',
        phone: '',
        issue_type: '',
        issue_description: ''
      });

      toast.update(toastId, {
        render: "Message sent successfully! We'll get back to you soon.",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });
    } catch (error) {
      toast.update(toastId, {
        render: "Failed to send message. Please try again.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  return (
    <div className="support">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <motion.div 
        className="support-header"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h1>Need Assistance?</h1>
        <p>We're here for you!</p>
      </motion.div>

      <div className="support-grid">
        <Card className="contact-card">
          <motion.form 
            className="contact-form"
            {...fadeIn}
            onSubmit={handleSubmit}
          >
            <h2>Send us a message</h2>
            <div className="form-group">
              <motion.input 
                whileFocus={{ scale: 1.02 }}
                type="text" 
                name="name" 
                placeholder="Name" 
                required 
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <motion.input 
                whileFocus={{ scale: 1.02 }}
                type="phone" 
                name="phone" 
                placeholder="Phone Number" 
                required 
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <motion.select 
                whileFocus={{ scale: 1.02 }}
                name="issue_type" 
                required
                value={formData.issue_type}
                onChange={handleInputChange}
              >
                <option value="">Select Issue Type</option>
                <option value="general">General</option>
                <option value="technical">Technical Support</option>
                <option value="billing">Billing Inquiry</option>
              </motion.select>
            </div>
            <div className="form-group">
              <motion.textarea 
                whileFocus={{ scale: 1.02 }}
                name="issue_description" 
                placeholder="How can we help?" 
                required
                value={formData.issue_description}
                onChange={handleInputChange}
              />
            </div>
            <motion.button 
              type="submit"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Sending...' : 'Send Message'}
            </motion.button>
          </motion.form>
        </Card>

        <Card className="info-card">
          <motion.div 
            className="company-info"
            {...fadeIn}
            transition={{ delay: 0.2 }}
          >
            <h2>Connect With Us</h2>
            <div className="info-grid">
              <motion.div 
                className="info-item"
                whileHover={{ scale: 1.05 }}
              >
                <FaMapMarkerAlt />
                <p>Privisio Pvt. Ltd.<br />
                16/14 1st Cross Street,<br/>CIT Colony, Mylapore<br />Chennai, TN 600004
                </p>
              </motion.div>
              <motion.div 
                className="info-item"
                whileHover={{ scale: 1.05 }}
              >
                <FaPhoneAlt />
                <p>+91 7299941412</p>
              </motion.div>
            </div>

            <div className="socials">
              <motion.a 
                href="https://www.instagram.com/privilege_nightlife?"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.2, rotate: 5 }}
              >
                <FaInstagram />
              </motion.a>
              <motion.a 
                href="mailto:support@privilege.network"
                whileHover={{ scale: 1.2, rotate: -5 }}
              >
                <FaEnvelope />
              </motion.a>
            </div>
          </motion.div>
        </Card>
      </div>
    </div>
  );
}

export default Support;