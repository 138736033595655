import React from 'react';
import { motion } from 'framer-motion';
import Card from '../components/Card';
import './PolicyPages.css';

function Terms() {
  const createMarkup = (content: string) => {
    return { __html: content };
  };

  return (
    <div className="policy-page">
      <motion.div 
        className="policy-header"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <h1>Terms and Conditions</h1>
        <p>Last updated: {new Date("11/11/2024").toLocaleDateString()}</p>
      </motion.div>

      <motion.div 
        className="policy-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        {sections.map((section, index) => (
          <Card key={index} className="policy-section">
            {section.title && <h2>{section.title}</h2>}
            <div 
              className="policy-content-text"
              dangerouslySetInnerHTML={createMarkup(section.content)} 
            />
          </Card>
        ))}
      </motion.div>
    </div>
  );
}

const sections = [
  {
    title: "",
    content: "Welcome to Privilege App! These Terms and Conditions ('Terms') govern your access to and use of our mobile application ('App'), operated by Privisio Pvt. Ltd. ('we,' 'our,' or 'us'). By downloading, accessing, or using our App, you agree to these Terms. Please read them carefully."
  },
  {
    title: "1. Acceptance of Terms",
    content: "By creating an account, accessing, or using Privilege App, you agree to abide by these Terms and our Privacy Policy. If you disagree with any part of the Terms, please refrain from using the App."
  },
  {
    title: "2. Account Registration and Security",
    content: `<b>Eligibility:</b> You must be at least 21 years old to use this App. By registering, you affirm that you meet this age requirement.<br><br>
    <b>Account Responsibility:</b> You are responsible for maintaining the confidentiality of your login credentials and all activities that occur under your account.<br><br>
    <b>Account Suspension/Termination:</b> We reserve the right to suspend or terminate accounts that engage in prohibited conduct, as described below.`
  },
  {
    title: "3. End User License Agreement (EULA)",
    content: `<b>License Grant:</b> We grant you a limited, non-exclusive, non-transferable, and revocable license to use the App for personal, non-commercial purposes. This license is conditioned on your compliance with these Terms.<br><br>
    <b>Ownership:</b> All rights, title, and interest in and to the App (including, but not limited to, code, design, logos, trademarks, and other intellectual property) are owned by Privisio Pvt. Ltd.<br><br>
    <b>Restrictions:</b> You may not:
    <ul>
      <li>Modify, distribute, or create derivative works based on the App.</li>
      <li>Reverse-engineer, decompile, or otherwise attempt to extract the source code of the App.</li>
      <li>Use the App for any purpose other than as explicitly authorized by these Terms.</li>
    </ul>`
  },
  {
    title: "4. User Content",
    content: `<b>Posting Content:</b> You may share events, images, videos, and other content ("User Content") on the App. By posting User Content, you grant us a worldwide, non-exclusive, royalty-free license to use this content.<br><br>
    <b>Content Responsibility:</b> You are solely responsible for all User Content posted via your account. You agree not to post any content that:
    <ul>
      <li>Violates the intellectual property or privacy rights of others</li>
      <li>Contains harmful or offensive language, hate speech, or explicit content</li>
      <li>Promotes illegal activities or is in violation of any applicable law</li>
    </ul>`
  },
  {
    title: "5. Plans and Moments",
    content: `<b>Plans Creation:</b> Users can create and post "Plans" that their friends or community members can join. All plan details must be accurate and updated promptly if any changes occur.<br><br>
    <b>Plans Participation:</b> Participation in plans is voluntary, and Privisio Pvt. Ltd. assumes no responsibility for user interactions, accidents, or any issues arising from participation.<br><br>
    <b>Plans Guidelines:</b> Plans must comply with community standards, including respect for public spaces, local regulations, and attendee safety.<br><br>
    <b>Moments:</b> Users can share "Moments" (images, videos, and other content) on the App. All moments must comply with our content guidelines and respect the privacy and rights of others.`
  },
  {
    title: "6. Privacy and Data Protection",
    content: "Your privacy is important to us. Our Privacy Policy explains how we collect, use, and protect your personal information. By using our App, you consent to our data practices as outlined in the Privacy Policy."
  },
  {
    title: "7. Modifications and Termination",
    content: `We reserve the right to modify these Terms at any time. The revised Terms will be posted with an updated "Last Updated" date. Your continued use of the App after such modifications constitutes your acceptance of the new Terms.<br><br>
    We may terminate or suspend your access to the App at any time, without prior notice or liability, for any reason, including if you breach these Terms.`
  },
  {
    title: "8. Contact Us",
    content: "If you have any questions about these Terms, please contact us at: support@privilege.network"
  }
];

export default Terms;