import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaInstagram, FaTwitter, FaTiktok, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <motion.footer 
      className="footer"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="footer-content">
        <div className="footer-section">
          <motion.div 
            className="footer-brand"
            whileHover={{ scale: 1.05 }}
          >
            <h3>PRIVILEGE</h3>
            <p>Elevating Your Nightlife Experience</p>
          </motion.div>
        </div>

        <motion.div 
          className="footer-section quick-links"
          initial={{ x: -20 }}
          animate={{ x: 0 }}
          transition={{ delay: 0.2 }}
        >
          <h4>Quick Links</h4>
          {/* <Link to="/about">About</Link> */}
          <Link to="/support">Support</Link>
          <Link to="/terms">Terms</Link>
          <Link to="/privacy">Privacy</Link>
        </motion.div>

        <motion.div 
          className="footer-section social-links"
          initial={{ x: -20 }}
          animate={{ x: 0 }}
          transition={{ delay: 0.3 }}
        >
          <h4>Connect</h4>
          <div className="social-icons">
            <motion.a 
              href="https://www.instagram.com/privilege_nightlife" 
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ y: -5, scale: 1.1 }}
            >
              <FaInstagram />
            </motion.a>
            <motion.a 
                href="mailto:support@privilege.network"
                whileHover={{ y: -5, scale: 1.1 }}
              >
                <FaEnvelope />
            </motion.a>
            {/* <motion.a 
              href="https://twitter.com/privilege" 
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ y: -5, scale: 1.1 }}
            >
              <FaTwitter />
            </motion.a>
            <motion.a 
              href="https://tiktok.com/@privilege" 
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ y: -5, scale: 1.1 }}
            >
              <FaTiktok />
            </motion.a>
            <motion.a 
              href="https://linkedin.com/company/privilege" 
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ y: -5, scale: 1.1 }}
            >
              <FaLinkedin />
            </motion.a> */}
          </div>
        </motion.div>
      </div>
      
      <motion.div 
        className="footer-bottom"
        initial={{ y: 20 }}
        animate={{ y: 0 }}
        transition={{ delay: 0.4 }}
      >
        <p>&copy; {currentYear} Privisio Pvt. Ltd. All rights reserved.<br />GSTIN: 33AAOCP6158K1ZW</p>
      </motion.div>
    </motion.footer>
  );
};

export default Footer;