import React from 'react';
import { motion } from 'framer-motion';
import Card from '../components/Card';
import ReactMarkdown from 'react-markdown';
import './PolicyPages.css';

function Privacy() {
  const createMarkup = (content: string) => {
    return { __html: content };
  };

  return (
    <div className="policy-page">
      <motion.div 
        className="policy-header"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <h1>Privacy Policy</h1>
        <p>Last updated: {new Date("11/11/2024").toLocaleDateString()}</p>
      </motion.div>

      <motion.div 
        className="policy-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        {sections.map((section, index) => (
          <Card key={index} className="policy-section">
            {section.title && <h2>{section.title}</h2>}
            <div 
              className="policy-content-text"
              dangerouslySetInnerHTML={createMarkup(section.content)} 
            />
          </Card>
        ))}
      </motion.div>
    </div>
  );
}

const sections = [
  {
    title: "",
    content: "This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Privilege App and tells You about Your privacy rights and how the law protects You.<br>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy."
  },
  {
    title: "Interpretation and Definitions",
    content: `<b>Interpretation</b><br>
    The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or plural.
    <br><b>Definitions</b><br>
For the purposes of this Privacy Policy:<br>
<ul><li>App means the "Privilege Nightlife" software application provided by the Company.</li>
<li>Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Privisio Pvt. Ltd.</li>
<li>Country refers to: India</li>
<li>Personal Data is any information that relates to an identified or identifiable individual.</li>
<li>Service refers to the App.</li>
<li>Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
<li>You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
</ul>`
  },
  {
    title: "Collecting and Using Your Personal Data",
    content: `<h3>Types of Data Collected</h3><b>Personal Data</b>
While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
<ul><li>First name and last name</li>
<li>Phone number</li>
<li>Date of Birth</li>
<li>Usage Data</li>
</ul><b>Usage Data</b><br>
Usage Data is collected automatically when using the Service.<br>
Usage Data may include information such as Your Device's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.<br>
When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers, and other diagnostic data.<br>
We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.`
  },
  {
    title: "Use of Your Personal Data",
    content: `The Company may use Personal Data for the following purposes:
<ul><li>To provide and maintain our Service, including to monitor the usage of our Service.</li>
<li>To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
<li>To contact You: To contact You by SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products, or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
<li>To provide You with news, special offers, and general information about other goods, services, and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</li>
<li>To manage Your requests: To attend and manage Your requests to Us.</li>
</ul>`
  },
  {
    title: "Data Retention and Transfer",
    content: `<b>Retention of Your Personal Data</b><br>
The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our legal agreements and policies.<br>
<b>Transfer of Your Personal Data</b><br>
Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.<br>
The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy.`
  },
  {
    title: "Disclosure of Your Personal Data",
    content: `<b>Business Transactions</b><br>
If the Company is involved in a merger, acquisition, or asset sale, Your Personal Data may be transferred.<br>
<b>Law enforcement</b><br>
Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities.<br>
<b>Other legal requirements</b><br>
The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
<ul><li>Comply with a legal obligation</li>
<li>Protect and defend the rights or property of the Company</li>
<li>Prevent or investigate possible wrongdoing in connection with the Service</li>
<li>Protect the personal safety of Users of the Service or the public</li>
<li>Protect against legal liability</li>
</ul>`
  },
  {
    title: "Final Provisions",
    content: `<b>Security of Your Personal Data</b><br>
The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure.<br>
<b>Changes to this Privacy Policy</b><br>
We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.<br>
<b>Contact Us</b><br>
If you have any questions about this Privacy Policy, You can contact us:<br>
By email: support@privilege.network`
  }
];

export default Privacy;