import React from 'react';
import './Card.css';
import './Image.css';

interface CardProps {
  children: React.ReactNode;
  className?: string;  // Add optional className prop
}

function Card({ children, className = '' }: CardProps) {  // Add className with default empty string
  return (
    <div className={`card ${className}`}> 
      {children}
    </div>
  );
}

export default Card;